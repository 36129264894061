import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Modules from "../components/modules"
// import { useEffect } from "react"
// import { useScroll } from "../hooks/useScroll"

// import clsx from "clsx"

export const query = graphql`
  query PageBySlug($slug: String!) {
    sanityPageModulaire(slug: { current: { eq: $slug } }) {
      id
      seo {
        ...seo
      }
      home
      slug {
        current
      }
      title
      modules {
        ... on SanityTexteUI {
          _key
          _type
          title
          aside {
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
          }
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }

        # ... on SanitySliderUI {
        #   _key
        #   _type
        #   images: _rawImages(resolveReferences: { maxDepth: 10 })
        #   showCaption
        #   showPager
        # }
        ... on SanitySliderUI {
          _key
          _type
          showCaption
          slides {
            link {
              _type
              slug {
                current
              }
            }
            image {
              caption
              image: _rawImage(resolveReferences: { maxDepth: 10 })
            }
          }
        }

        ... on SanityProductsUI {
          _key
          _type
          title
          link {
            label
            link {
              ... on SanityPageModulaire {
                _type
                slug {
                  current
                }
              }
            }
          }
          showFilters
          products {
            ...productCard
          }
        }
        ... on SanityContributeursUI {
          _key
          _type
          title
          contributeur {
            _type
            title
            role
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
            products {
              ...productCard
            }
          }
        }
      }
    }
  }
`

const Modulaire = ({ data }) => {
  const { seo, slug, home, modules } = data.sanityPageModulaire

  return (
    <div className="main-area">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`page-modulaire page-${slug.current}`}
          page={!home}
        />
      )}

      {modules && <Modules input={modules} />}
    </div>
  )
}

export default Modulaire
